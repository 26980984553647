import { uouPayButtonClick } from '@wix/bi-logger-pay-button/v2';
import { experimentsService } from '@wix/cashier-common/dist/src/utils/experimentsService';
import { getPremiumStatus, makeRequestForOrder } from '../api';
import { APP_ID, MODAL_SIZE } from '../constants';
import { buttonRole } from '../editor/structures/button';
import { isPreview } from '../utils/states';
import { getNoPaymentsPopupUrl, getUpgradeAndTestPaymentModel } from '../utils/links';
import { getBiLogger } from '../utils/biLogger';
import { PayAppBIParams } from '../enums/PayAppBIParams';
import { defaultSentryConfig } from '../utils/sentry';
import { createHandleError, defaultErrorHandler, HandleError } from '../utils/handleError';
import { ViewerInteractionName } from '../enums/ViewerInteractionName';
import { MessageFromPopup } from '../enums/PremiumPopup';

export default {
  initAppForPage: () => {
    return Promise.resolve({});
  },
  createControllers: (controllerConfigs) => {
    return controllerConfigs.map((controller) =>
      Promise.resolve({
        pageReady: (_, wixCode) => {
          let handleError: HandleError = defaultErrorHandler;
          let fedOpsLogger;

          try {
            const appId = controller.appParams.appDefinitionId;
            const widgetId = controller.type;
            const fedOpsLoggerFactory = controller.platformAPIs.fedOpsLoggerFactory;
            fedOpsLogger = fedOpsLoggerFactory.getLoggerForWidget({ appId, widgetId });
            fedOpsLogger.interactionStarted(ViewerInteractionName.ALE);
          } catch (e) {
            handleError(e as Error);
          }
          try {
            const tags = {
              msId: controller.platformAPIs.bi.metaSiteId,
              userId: controller.platformAPIs.bi.siteMemberId || controller.platformAPIs.bi.visitorId,
              locale: controller.wixCodeApi.window.locale,
            };

            const sentryInstance = controller.platformAPIs.monitoring.createMonitor(
              defaultSentryConfig.dsn,
              (config) => ({
                ...config,
                ...defaultSentryConfig.config,
                tags: { ...tags },
              }),
            );
            handleError = createHandleError(sentryInstance.captureException.bind(sentryInstance));
          } catch (e) {
            handleError(e as Error, 'Sentry is not initialized');
          }

          try {
            const instance = wixCode.site.getAppToken(APP_ID);
            const {
              config: { productId, termsAndConditionsLink },
            } = controller;
            const metaSiteId = controller.platformAPIs.bi.metaSiteId;
            const webBiLogger = controller.platformAPIs.biLoggerFactory();
            experimentsService.init({
              scope: 'payments',
            });
            const biLogger = getBiLogger(
              {
                appid: APP_ID,
                appInstanceId: controller.appParams.appInstanceId,
                memberId: controller.platformAPIs.bi.siteMemberId,
                msid: metaSiteId,
                origin: controller.wixCodeApi.location.url,
                paybuttonid: controller.appParams.instanceId,
                sessionId: controller.platformAPIs.bi.viewerSessionId,
                visitorId: controller.platformAPIs.bi.visitorId,
              },
              webBiLogger,
            );

            const button = controller.$w(`@${buttonRole}`);
            const paymentOptions = termsAndConditionsLink ? { termsAndConditionsLink } : {};
            const viewMode = wixCode.window.viewMode;

            const payAppBiParams: PayAppBIParams = {
              sessionId: controller.platformAPIs.bi.viewerSessionId,
              pbId: controller.appParams.instanceId,
              pbOrigin: controller.wixCodeApi.location.url,
              siteMemberId: controller.platformAPIs.bi.siteMemberId,
            };

            const startPayment = (orderId) => {
              return wixCode.pay.startPayment(orderId, {
                ...paymentOptions,
                ...payAppBiParams,
              });
            };

            const deviceType = controller.wixCodeApi.window.formFactor;

            const openUpgradeAndPlaceTestModal = async (orderId) => {
              const event = await wixCode.window.openModal(getUpgradeAndTestPaymentModel(instance), {
                width: MODAL_SIZE[deviceType].width,
                height: MODAL_SIZE[deviceType].height,
                theme: 'BARE',
              });

              if (event && event.message === MessageFromPopup.PlaceTestOrder) {
                return startPayment(orderId);
              }
            };

            const openNoPaymentsPopup = () => {
              return wixCode.window.openModal(
                getNoPaymentsPopupUrl({ instance, referralInfo: 'payButton', startLoadTime: Date.now() }),
                {
                  width: MODAL_SIZE[deviceType].width,
                  height: MODAL_SIZE[deviceType].height,
                  theme: 'BARE',
                },
              );
            };

            const onButtonClick = async () => {
              biLogger.report(uouPayButtonClick({}));

              const { orderId } = await makeRequestForOrder(instance, productId);
              const isPremium = await getPremiumStatus(instance);

              if (isPremium) {
                fedOpsLogger.interactionStarted(ViewerInteractionName.PAY_BUTTON_OPEN_PAY_APP);
                return startPayment(orderId);
              }

              if (!isPremium && isPreview(viewMode)) {
                fedOpsLogger.interactionStarted(ViewerInteractionName.OPEN_PREMIUM_POPUP);
                return openUpgradeAndPlaceTestModal(orderId);
              }

              if (!isPremium) {
                fedOpsLogger.interactionStarted(ViewerInteractionName.OPEN_NO_PAYMENTS_POPUP);
                return openNoPaymentsPopup();
              }
            };

            button.onClick(async () => {
              try {
                return await onButtonClick();
              } catch (error) {
                handleError(error as Error);
              }
            });
            fedOpsLogger.interactionEnded(ViewerInteractionName.ALE);
          } catch (error) {
            handleError(error as Error);
          }
        },
        exports: () => ({}),
      }),
    );
  },
};
